// Burger Menu
var navbar = document.getElementById('navbar');
var burger = document.getElementById('burger');
burger && burger.addEventListener('click', function() {
  navbar.classList.toggle('show');
});

// Smooth Scroll
var height_fixed_header = 0;
var speed = 500;
var moving_frequency = 15;
var links = document.querySelectorAll('a');
var href;

links.forEach(function(link) {
  href = (link.attributes.href === undefined) ? null : link.attributes.href.nodeValue.toString();

  if (href === null || href.length < 1 || href.indexOf('#') === -1) {
    return;
  }

  link.onclick = function(evt) {
    var href = this.attributes.href.nodeValue.toString();
    var id = href.substr(href.indexOf('#') + 1);
    var element = document.getElementById(id);

    if (!element) {
      return true;
    }

    evt.preventDefault();

    scrollToElement(id);
  }
});

function getScrollTopElement(e) {
  var top = height_fixed_header * -1;

  while (e.offsetParent != undefined && e.offsetParent != null) {
    top += e.offsetTop + (e.clientTop != null ? e.clientTop : 0);
    e = e.offsetParent;
  }

  return top;
}

function getScrollTopDocument() {
  return window.pageYOffset !== undefined
    ? window.pageYOffset
    : document.documentElement.scrollTop !== undefined
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
}

function scrollToElement(id) {
  var element = document.getElementById(id);
  var hop_count = (speed - (speed % moving_frequency)) / moving_frequency;
  var getScrollTopDocumentAtBegin = getScrollTopDocument();
  var gap = (getScrollTopElement(element) - getScrollTopDocumentAtBegin) / hop_count;

  if (window.history && typeof window.history.pushState == 'function') {
    window.history.pushState({}, undefined, '#' + id);
  }

  for (var i = 1; i <= hop_count; i++) {
    (function() {
      var hop_top_position = gap * i;
      setTimeout(function() {
        window.scrollTo(0, hop_top_position + getScrollTopDocumentAtBegin);
      }, moving_frequency * i);
    })();
  }
}

if (window.location.hash.length > 0) {
  scrollToElement(window.location.hash.substr(1));
}

// Click on Products
var links = document.querySelectorAll('.column-links a');
var pars = document.querySelectorAll('.column-links div.column');

links.forEach(function(column) {
  column.addEventListener('click', function(evt) {
    evt.preventDefault();
    var justHide = column.classList.contains('active');
    links.forEach(function(link) {
      link.classList.remove('active');

      if (justHide) {
        link.classList.remove('hide');
      } else {
        link.classList.add('hide');
      }
    });
    pars.forEach(function(par) { par.classList.add('hide'); });

    if (justHide) {
      return;
    }

    column.classList.add('active');
    column.classList.remove('hide');
    column.nextElementSibling.classList.remove('hide');
  });
});

pars.forEach(function(par) {
  par.querySelector('.button').addEventListener('click', function(evt) {
    evt.preventDefault();
    pars.forEach(function(par) { par.classList.add('hide'); });
    links.forEach(function(link) {
      link.classList.remove('active');
      link.classList.remove('hide');
    });
  });
});